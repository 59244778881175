import React, { Component } from "react";

class Resume extends Component {
  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      console.log("skill message: ", this.props.data);
      var education = this.props.data.education.map(function(education) {
        return (
          <div key={education.school}>
            <h4>{education.school}</h4>
            <p className="info">
              {education.degree} <span>&bull;</span>
              <em className="date">{education.graduated}</em>
              <br/> {education.description}
            </p>
          </div>
        );
      });
      var work = this.props.data.work.map(function(work) {
        return (
          <div key={work.company}>
            <h4>{work.company}</h4>
            <p className="info">
              {work.title}
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <p>{work.description}</p>
          </div>
        );
      });
      var skills = this.props.data.skills.map(function(skills) {
        return (
          <div key={skills.name}>
            <h4>{skills.name}</h4>
            <p>{skills.description}</p>
          </div>
        );
      });
      var achievements = this.props.data.achievements.map(function(achievements) {
        return (
          <div key={achievements.role}>
            <h4>{achievements.role}</h4>
            <p className="info">
              <span>&bull;</span><em className="date">{achievements.started}</em>
              <br/> {achievements.description}
            </p>
          </div>
        );
      });
    }

    return (
      <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Education</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{education}</div>
            </div>
          </div>
        </div>

        <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">{work}</div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">{skills}</div>
        </div>

        <div className="row achievements">
          <div className="three columns header-col">
            <h1>
              <span>Achievements</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{achievements}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
